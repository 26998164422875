<template>
  <div
    class="cx-cooperation-box"
    :class="is_mobile ? 'm-cx-cooperation-box' : 'pc-cx-cooperation-box'"
  >
    <div class="cx-co-contact">
      <div class="cx-co-title">{{ $t("coopearation.contact_us") }}</div>
      <div class="cx-co-li">
        <div class="cx-co-msg">
          <div class="cx-co-key">
            <img class="cx-co-icon" :src="icon_a" />
            {{ $t("coopearation.business_coperation") }}
          </div>
          <div class="cx-co-key">thunderx@thunderxauto.com</div>
        </div>
      </div>
      <div class="cx-co-li">
        <div class="cx-co-msg">
          <div class="cx-co-key">
            <img class="cx-co-icon cx-co-icon-b" :src="icon_b" />
            {{ $t("coopearation.resume_delivery") }}
          </div>
          <div class="cx-co-key">recruit@thunderxauto.com</div>
        </div>
      </div>
      <div class="cx-co-li">
        <div class="cx-co-msg">
          <div class="cx-co-key">
            <img class="cx-co-icon cx-co-icon-c" :src="icon_c" />
            {{ $t("coopearation.phone_number") }}
          </div>
          <div class="cx-co-key">021-61105300</div>
        </div>
      </div>
    </div>
    <div class="cx-co-form">
      <div class="cx-co-title">{{ $t("coopearation.business_consulting") }}</div>
      <div class="cx-form-wrap">
        <div class="cx-form-row">
          <div
            class="cx-form-label"
            :class="company_name.invalidate ? 'cx-form-red' : ''"
          >
            {{ $t("coopearation.company") }} *
          </div>
          <div class="cx-form-input">
            <input
              v-model="company_name.value"
              type="text"
              @blur="checkInput('company_name')"
              @input="checkInput('company_name')"
            />
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label" :class="name.invalidate ? 'cx-form-red' : ''">
            {{ $t("coopearation.name") }} *
          </div>
          <div class="cx-form-input">
            <input v-model="name.value" type="text" @blur="checkInput('name')" @input="checkInput('name')" />
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label" :class="position.invalidate ? 'cx-form-red' : ''">
            {{ $t("coopearation.position") }} *
          </div>
          <div class="cx-form-input">
            <input v-model="position.value" type="text" @blur="checkInput('position')" @input="checkInput('position')" />
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label" :class="email.invalidate ? 'cx-form-red' : ''">
            {{ $t("coopearation.email") }} *
          </div>
          <div class="cx-form-input">
            <input v-model="email.value" type="text" @blur="checkInput('email')" @input="checkInput('email')" />
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label" :class="mobile.invalidate ? 'cx-form-red' : ''">
            {{ $t("coopearation.mobile") }} *
          </div>
          <div class="cx-form-input">
            <input v-model="mobile.value" type="text" @blur="checkInput('mobile')" @input="checkInput('mobile')" />
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label" :class="content.invalidate ? 'cx-form-red' : ''">
            {{ $t("coopearation.context") }} *
          </div>
          <div class="cx-form-input">
            <input v-model="content.value" type="text" @blur="checkInput('content')" @input="checkInput('content')" />
          </div>
        </div>
      </div>
      <div class="cx-form-submit" @click="submit">{{ $t("coopearation.submit") }}</div>
    </div>
    <img class="co-close-btn" :src="coop_close" @click="$emit('hideCooparationForm')" />
    <slot></slot>
  </div>
</template>
<script>
import validate from '../utils/data.validata';
export default {
  name: "Cooperation",
  props: {
    source:{
      type:String,
      default:'',
    }
  },
  data() {
    return {
      icon_a: require("@/assets/coop-icon-a.png"),
      icon_b: require("@/assets/coop-icon-b.png"),
      icon_c: require("@/assets/coop-icon-c.png"),
      coop_close: require("@/assets/close-coop.png"),

      company_name: {
        value: "",
      },

      name: {
        value: "",
      },

      position: {
        value: "",
      },

      email: {
        value: "",
      },

      mobile: {
        value: "",
      },

      content: {
        value: "",
      },
      submit_lock:false
    };
  },
  methods: {
    checkInput(name) {
      if (name == "company_name") {
        if (!this.company_name.value) {
          this.company_name.invalidate = true;
        } else {
          this.company_name.invalidate = false;
          return true;
        }
      } else if (name == "name") {
        if (!this.name.value) {
          this.name.invalidate = true;
        } else {
          this.name.invalidate = false;
          return true;
        }
      } else if (name == "position") {
        if (!this.position.value) {
          this.position.invalidate = true;
        } else {
          this.position.invalidate = false;
          return true;
        }
      } else if (name == "email") {
        if (!validate.isEmail(this.email.value)) {
          this.email.invalidate = true;
        } else {
          this.email.invalidate = false;
          return true;
        }
      } else if (name == "mobile") {
        if (!this.mobile.value) {
          this.mobile.invalidate = true;
        } else {
          this.mobile.invalidate = false;
          return true;
        }
      } else if (name == "content") {
        if (!this.content.value) {
          this.content.invalidate = true;
        } else {
          this.content.invalidate = false;
          return true;
        }
      }
      this.$forceUpdate();
    },
    submit() {
      let that=this;
      if(this.checkInput("company_name")&&
      this.checkInput("name")&&
      this.checkInput("position")&&
      this.checkInput("email")&&
      this.checkInput("mobile")&&
      this.checkInput("content")
      ){
        if(this.submit_lock){
          return false;
        }
        this.submit_lock=true;
        this.api
        .consultLogAdd({
          company_name: this.company_name.value,
          name: this.name.value,
          position: this.position.value,
          email: this.email.value,
          mobile: this.mobile.value,
          content: this.content.value,
          source:this.source
        })
        .then((res) => {
          if(res.code==200){
            that.$emit('hideCooparationForm')
            alert('提交成功')
          }else if(res.code==400){
            alert(res.msg)
          }
          this.submit_lock=false;
        });
      }
    },
  },
  components: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.cx-cooperation-box {
  position: relative;
  width: 1170px;
  height: 571px;
  margin-top: -300px;
  margin-left: -585px;
  overflow: hidden;
  float: left;
}

.co-close-btn {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 22px;
  top: 22px;
}
.cx-co-contact {
  width: 410px;
  height: 571px;
  float: left;
  background: rgba(83, 84, 85, 1);
  overflow: hidden;
}
.cx-co-form {
  width: 760px;
  float: left;
  height: 571px;
  background: rgba(99, 99, 99, 1);
  overflow: hidden;
}
.cx-co-title {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  margin: 51px auto 41px;
}
.cx-co-li {
  padding: 20px 0px;
}
.cx-co-msg {
  height: 45.5px;
  font-size: 18px;
  text-align: left;
  border-left: 1px solid rgba(255, 255, 255, 1);  ;
  line-height: 26px;
  padding-left: 8px;
  margin-left: 109px;
}

.cx-co-key {
  position: relative;
  color:#fff;
}
.cx-co-icon {
  position: absolute;
  width: 34px;
  height: 26px;
  z-index: 11;
  left: -60px;
}

.cx-co-icon-b {
  width: 26.5px;
  height: 24px;
}
.cx-co-icon-c {
  width: 24px;
  height: 24px;
}
.cx-form-wrap {
}
.cx-form-row {
  overflow: hidden;
  margin-bottom: 12px;
}
.cx-form-label {
  font-size: 18px;
  margin-right: 10px;
  line-height: 40px;
  width: 170px;
  float: left;
  color:#fff;
  text-align: right;
}
.cx-form-red {
  color: #ff0000;
}
.cx-form-input {
  width: 460px;
  height: 36px;
  float: left;
  padding: 2px 10px;
  border: 1px solid rgba(196, 196, 196, 1);
}
.cx-form-input input {
  display: block;
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  width: 460px;
  color: #fff;
}
.cx-form-submit {
  background: rgba(255, 255, 255, 1);
  width: 264px;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  color: rgba(0, 0, 0, 1);
  margin: 40px auto 0px;
  cursor: pointer;
}
.m-cx-cooperation-box {
  width: 690px;
  height: 1180px;
  height: auto;
  margin-left: -345px;
  margin-top: -590px;
  .cx-co-contact {
    width: 690px;
    height: 480px;
    .cx-co-title {
      font-size: 37px;
    }
    .cx-co-li {
      padding: 25px;
      .cx-co-msg {
        border-left: none;
        margin-left: 0px;
        .cx-co-key {
          text-align: center;
          font-size: 25px;
          margin-bottom: 10px;
          .cx-co-icon {
            position: relative;
            display: inline-block;
            left: 0px;
            margin: 0px 10px;
          }
        }
      }
    }
  }
  .cx-co-form {
    width: 690px;
    height: 700px;
    .cx-co-title {
      font-size: 37px;
    }

    .cx-form-wrap {
      width: 600px;
      margin: 0 auto;
      .cx-form-row {
        padding: 5px 0px;
        .cx-form-label {
          font-size: 25px;
        }
      }
    }
    .cx-form-input {
      height: 45px;
      width: 375px;
      border: 2px solid rgba(196, 196, 196, 1);
      input {
        height: 41px;
        width: 375px;
      }
    }
  }
}
</style>
