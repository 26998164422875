/**
 * Created by leo on 20/06/06.
 * @author leo.wang 17503046966@163.com;
 */

var validate={
  /**
   * 验证是否是手机号.
   * @param {String} phone_number 
   */
  isPhoneNumber(phone_number) {
    let fiter = /^[1][0-9]{10}$/;
    if (fiter.test(phone_number)) {
      return true
    } else {
      return false
    }
  },

  /**
   * 验证是否是邮箱.
   * @param {String} email 
   */
  isEmail(email) {
    // eslint-disable-next-line no-useless-escape
    let fiter = /^([a-zA-Z0-9\.])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (fiter.test(email)) {
      return true
    } else {
      return false
    }
  },

  /**
   * 验证字符串是否都是数字.
   * @param {String} checked_string 
   * 
   */
  isNumString(checked_string){
    let fiter = /^[0-9]*$/;
    if (fiter.test(checked_string)) {
      return true
    } else {
      return false
    }
  },

  /**
   * 验证是否是身份证号.
   * @param {String} id_card 
   */
  isIdCard(id_card) {
    let fiter = /(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/;
    if (fiter.test(id_card)) {
      return true
    } else {
      return false
    }
  },

  /**
   * 验证字符串是否为空
   * @param {String} value 
   */
  isEmpty(value){
     return value=='';
  }
}



export default validate;