<template>
  <div class="advantage-box" :class="is_mobile ? 'm-advantage-box' : 'advantage-box'">
    <img class="advantage-bg-img" :src="bg" />
    <div class="advantage-info">
      <div class="advantage-lable">{{ title }}</div>
      <div class="advantage-desc">{{ description }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProAdvantage",
  props: {
    bg: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    }
  },
  data() {
    return {
    };
  },
  methods: {},
  components: {},
  mounted() { },
};
</script>
<style lang="less" scoped>
.advantage-box {
  position: relative;
  width: 1320px;
  height: 600px;
  margin: 0 auto;
}

.advantage-bg-img {
  width: 1320px;
  height: 600px;
  position: absolute;
  z-index: 0;
  left: 0px;
  top: 0px;
}

.advantage-info {
  position: absolute;
  top: 81px;
  left: 65px;
  width: 680px;
}

.advantage-lable {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 2.2px;
  line-height: 50px;
  color: transparent;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(158, 234, 255, 1) 100%);
  -webkit-background-clip: text;
  text-align: left;
  vertical-align: top;
}

.advantage-desc {
  width: 650px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  line-height: 34px;
  margin-top: 26px;
  white-space: pre-wrap;
  // text-shadow: 0 0 1px var(--un-text-shadow-color, rgb(0 0 0 / 0.2)), 0 0 1px var(--un-text-shadow-color, rgb(1 0 5 / 0.1));
}

.m-advantage-box {
  width: 690px;

  .advantage-bg-img {
    width: 690px;
    height: 692px;
  }

  .advantage-info {
    width: 650px;
    left: 30px;

    .advantage-lable {
      text-align: center;
    }

    .advantage-desc {
      font-size: 20px;
      text-align: center;
    }
  }
}</style>
