<template>
  <div class="contact-box" :class="is_mobile ? 'm-contact-box' : 'pc-contact-box'">
    <img class="contact-bg-img" :src="is_mobile?contact_bg_m:contact_bg" />
    <div class="contact-info">
      <div class="contact-lable">{{$t('pro.contact_tip')}}</div>
      <div class="contact-btn" @click="$emit('showCoopationForm')">{{$t('pro.contact_btn')}}</div>
    </div>
    <div classs="contact-form-pox"></div>
  </div>
</template>
<script>
export default {
  name: "Contact",
  props: {},
  data() {
    return {
      contact_bg:require('../assets/pro-contact-bg.png'),
      contact_bg_m:require('../assets/pro-contact-bg-m.png'),
    };
  },
  methods: {},
  components: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.contact-box{
  position: relative;
  height: 397px;
}
.contact-bg-img {
  position: absolute;
  width: 1920px;
  height: 397px;
  left:0px;
  top:0px;
  z-index: 0;
}
.contact-lable{
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 37px;
}
.contact-info{
  position: absolute;
  width: 400px;
  z-index: 11;
  top:113px;
  right: 252px;
  color: #ffffff;
}

.contact-btn {
  background: #90dafc;
  width: 230px;
  height: 35px;
  border-radius: 5px;
  line-height: 35px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  margin:0 auto;
  cursor: pointer;
}

.m-contact-box{
  position: relative;
  width: 750px;
  height: auto;
  .contact-bg-img{
    position: relative;
    height: 420px;
    width: 750px;
  }
  .contact-info{
    position: relative;
    width: 750px;
    left:0px;
    top:0px;
    padding:80px 0px;
    background-color: #000;
    .contact-btn{
        width: 340px;
        height: 70px;
        line-height: 70px;
        font-size: 25px;
        margin:20px auto;
      }
  }
}
</style>
